import React from 'react';
import _ from 'lodash';
import imagesLoaded from 'imagesloaded';
import style from './ProductGalleryLayout.scss';
import {DataHook as ProductImageItemDataHook} from '../MainMedia/ProductImage/ProductImage';
import {MainMedia} from '../MainMedia/MainMedia';
import {ProductMediaNavigation} from '../ProductMediaNavigation/ProductMediaNavigation';
import {ImageMode} from '@wix/wixstores-client-core/dist/es/src/media/constants';
import {GalleryNavigationLocation, GalleryNavigationPosition, GalleryNavigationType} from '../../../constants';
import classNames from 'classnames';
import {getMainImageRatio} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {DataHook as ThumbnailDataHook} from '../ProductMediaNavigation/MediaNavigationItem/Thumbnail/Thumbnail';
import md5 from 'md5';
import {convertCssValueToConfig} from '../../../commons/utils';
import {IProductDTO, LayoutConfig} from '../../../types/app-types';
import {ProductGalleryProvider} from '../ProductGalleryProvider/ProductGalleryProvider';
import {IMedia} from '../../../types/productDef';
import {Mode, WishlistButton} from '../../ProductPageButtonsContainer/WishlistButton/WishlistButton';
import {ShowOnMobileOnly} from '@wix/wixstores-client-common-components/dist/es/src/HOC/responsive/ShowOnMobileOnly/ShowOnMobileOnly';
import {ModalGallery} from '../../ModalGallery/ModalGallery';

export interface ProductGalleryLayoutProps extends ProvidedGlobalProps {
  product: IProductDTO;
  layoutConfig: LayoutConfig;
  imageMode: ImageMode;
  imageRatioId: number;
  navigationType: GalleryNavigationType;
  navigationPosition: GalleryNavigationPosition;
  navigationLocation: GalleryNavigationLocation;
}

interface ProductGalleryLayoutState {
  modalGalleryCurrentIndex: number;
  isModalGalleryOpen: boolean;
}

@withGlobalProps
export class ProductGalleryLayout extends React.Component<ProductGalleryLayoutProps, ProductGalleryLayoutState> {
  private reportedLoad = false;
  private imagesLoaded = false;
  public state = {
    modalGalleryCurrentIndex: 0,
    isModalGalleryOpen: false,
  };

  private isThumbnailsNavigationOfPositions(positions): boolean {
    const {navigationType, navigationPosition} = this.props;
    return (
      this.isWithThumbnails() &&
      navigationType === GalleryNavigationType.THUMBNAILS &&
      _.includes(positions, navigationPosition)
    );
  }

  private isHorizontalThumbnailsNavigation(): boolean {
    return this.isThumbnailsNavigationOfPositions([GalleryNavigationPosition.BOTTOM]);
  }

  private isVerticalThumbnailsNavigation(): boolean {
    return this.isThumbnailsNavigationOfPositions([GalleryNavigationPosition.LEFT, GalleryNavigationPosition.RIGHT]);
  }

  private getProductMediaNavigation(): JSX.Element {
    const {
      product,
      layoutConfig: {align, dimensions},
      navigationPosition,
      navigationLocation,
      navigationType,
      globals: {isMobile},
    } = this.props;
    const withDots = navigationType === GalleryNavigationType.DOTS;
    const conditionalClasses = {
      [style[`thumbnails-inside-${navigationPosition}-navigation`]]:
        !isMobile && navigationLocation === GalleryNavigationLocation.INSIDE,
    };
    const layoutDimensions = {...dimensions.thumbnails};
    if (navigationPosition === GalleryNavigationPosition.BOTTOM || isMobile) {
      layoutDimensions.heightConf = {num: 50, unit: 'px'};
    } else {
      layoutDimensions.heightConf = {...dimensions.mainMedia.heightConf};
      layoutDimensions.widthConf = {num: 50, unit: 'px'};
    }
    return (
      <div
        data-hook="thumbnails-container"
        className={classNames([
          'slick-thumbnails-container-hook',
          style.thumbnails,
          style[`thumbnails-position-${!isMobile ? navigationPosition : 'bottom'}`],
          conditionalClasses,
        ])}>
        <ProductMediaNavigation
          media={product.media}
          vertical={this.isVerticalThumbnailsNavigation()}
          align={align}
          layoutDimensions={layoutDimensions}
          withDots={withDots}
        />
      </div>
    );
  }

  private getMainMediaContainerClass(): string {
    const {
      layoutConfig: {withMediaBorder},
      navigationPosition,
    } = this.props;
    const withVerticalThumbnailsNavigation = this.isVerticalThumbnailsNavigation();
    return classNames([
      style.mainMediaContainer,
      style.contentBox,
      {
        [style[`main-media-${navigationPosition}-navigation`]]: withVerticalThumbnailsNavigation,
        [style.mainMediaBorder]: withMediaBorder,
      },
    ]);
  }

  private getRootClasses(withImageRatio: boolean): string {
    const {
      layoutConfig: {marginBottom},
    } = this.props;
    const horizontalNavigation = this.isHorizontalThumbnailsNavigation()
      ? [style.horizontalNavgationBottom, style[marginBottom]]
      : [];
    return classNames([
      style.root,
      ...horizontalNavigation,
      {
        [style.rootAdaptive]: !withImageRatio,
      },
    ]);
  }

  private reportLoad() {
    if (!this.reportedLoad && this.props.globals.isInteractive && this.imagesLoaded) {
      this.props.globals.appLoadBI.loaded();
      this.reportedLoad = true;
    }
  }

  public componentDidMount(): void {
    imagesLoaded(
      document.querySelectorAll(
        `[data-hook="${ThumbnailDataHook.image}"],[data-hook="${ThumbnailDataHook.video}"],[data-hook="${ProductImageItemDataHook.ProductImageItem}"]`
      ),
      () => {
        this.imagesLoaded = true;
        this.reportLoad();
        this.props.globals.updateLayout && this.props.globals.updateLayout();
      }
    );
  }

  public componentDidUpdate(): void {
    if (!this.reportedLoad && this.props.globals.isInteractive) {
      this.reportLoad();
    }
  }

  private isWithThumbnails(): boolean {
    const {
      globals: {hasMultipleMedia},
    } = this.props;
    return hasMultipleMedia;
  }

  public render() {
    const {
      product,
      layoutConfig: {withImageRatio, swipeToScroll, dimensions, allowMagicZoom, withDynamicHeight, withMediaBorder},
      imageRatioId,
      imageMode,
      globals: {shouldShowWishlistButton, withModalGallery},
    } = this.props;
    const keyMedia = product.media[0];
    const layoutDimensions = dimensions.mainMedia;
    const withProductMediaNavigation = this.isWithThumbnails();
    if (withImageRatio) {
      const {
        ratio: {width, height},
      } = getMainImageRatio(keyMedia as IMedia, imageRatioId);
      layoutDimensions.heightConf = {
        num: Math.ceil(layoutDimensions.widthConf.num * (height / width)),
        unit: 'px',
      };
    }
    if (withMediaBorder) {
      layoutDimensions.heightConf.num =
        layoutDimensions.heightConf.num - convertCssValueToConfig(style.sharedStyleVariables_borderWidth).num * 2;
      layoutDimensions.widthConf.num =
        layoutDimensions.widthConf.num - convertCssValueToConfig(style.sharedStyleVariables_borderWidth).num * 2;
    }

    return (
      <div
        data-hook="product-gallery-root"
        className={this.getRootClasses(withImageRatio)}
        onClick={() => withModalGallery && this.openModalGallery()}>
        {withModalGallery && this.renderModalGallery()}
        <ProductGalleryProvider
          withImageRatio={withImageRatio}
          imageRatioId={imageRatioId}
          media={product.media}
          key={md5(product.media.reduce((acc, m) => acc.concat(m.url), ''))}>
          <div data-hook="main-media-container" className={this.getMainMediaContainerClass()}>
            <MainMedia
              imageMode={imageMode}
              swipeToScroll={swipeToScroll}
              product={product}
              layoutDimensions={layoutDimensions}
              allowMagicZoom={allowMagicZoom && !withModalGallery}
              withDynamicHeight={withDynamicHeight}
            />
          </div>
          {withProductMediaNavigation && this.getProductMediaNavigation()}
        </ProductGalleryProvider>
        {shouldShowWishlistButton && (
          <ShowOnMobileOnly>
            <WishlistButton mode={Mode.FLOATING} />
          </ShowOnMobileOnly>
        )}
      </div>
    );
  }

  private openModalGallery() {
    this.setState({isModalGalleryOpen: true});
  }

  private closeModalGallery() {
    this.setState({isModalGalleryOpen: false, modalGalleryCurrentIndex: 0});
  }

  private renderModalGallery() {
    const {
      product,
      globals: {isMobile},
    } = this.props;
    const {modalGalleryCurrentIndex, isModalGalleryOpen} = this.state;

    return (
      <div data-hook="modal-gallery-on-dom">
        {isModalGalleryOpen && (
          <ModalGallery
            media={product.media}
            currentIndex={modalGalleryCurrentIndex}
            handleNavigateTo={i => this.setState({modalGalleryCurrentIndex: i})}
            handleClose={() => this.closeModalGallery()}
            isMobile={isMobile}
          />
        )}
      </div>
    );
  }
}
